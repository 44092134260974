body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    font-size: 18px;
    cursor: pointer;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999999999;
}

.overlay {
    background: rgba(49,49,49,0.8);
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    padding-bottom: 30px;
    border-radius: 20px;
    max-width: 600px;
    min-width: 300px;
    z-index: 999999999999;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}    

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    cursor: pointer;
}
.videos{
    border-radius: 20px;
    height: 290px;
}
.custom-dot-list-style{
    z-index: 2;
}
.carouselContainer .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border: 1px solid rgba(0, 0, 0, 0.431);
    background-color: #ffffff80;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  
  .carouselContainer .custom-arrow-left {
    left: 50px;
    
  }
  
  .carouselContainer .custom-arrow-right {
    right: 50px;
  }